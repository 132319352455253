import moment from 'moment';
import EditBusDetails from 'src/components/route/bus-master/EditBusMaster';
import { rydr } from 'src/proxy-config';
const api = (endpoint) => `/me/v1/${endpoint}`;
const ecommerceapi = (endpoint) => `/ca/v1/${endpoint}`;
const orderapi = (endpoint) => `/or/v1/${endpoint}`;
const authapi = (endpoint) => `/au/v1/${endpoint}`;
const externalapi = (endpoint) => `/ex/v1/${endpoint}`;
const externalapiV2 = (endpoint) => `/ex/v2/${endpoint}`;
const checkoutapi = (endpoint) => `/ch/v1/${endpoint}`;
const API = (endpoint) => `/v1/${endpoint}`;
const courierapi = (endpoint) => `/co/v1/${endpoint}`;
const reportapi = (endpoint) => `/re/v1/${endpoint}`;
const cabapi = (endpoint) => `/cab/v1/${endpoint}`;
const rydrapi = (endpoint) => `/ry/v1/${endpoint}`;

const endpoints = {
  auth: {
    login: () => api('auth/login'),
    vendor_login: () => ecommerceapi('vendors/verify-otp'),
    sendOTP: () => ecommerceapi('vendors/verify-mobile'),
    resetPassword: () => authapi('auth/reset-password'),
    verifyEmail: () => authapi('auth/forgot-password'),
    verityotp: () => authapi('auth/verify-otp'),
    pickrSendOTP: () => authapi('/pickr'),
    pickrLogin: () => authapi('/pickr/verify-otp'),
  },
  module: {
    list: () => api('modules/list'),
  },
  role: {
    list: () => api('roles'),
    create: () => api('roles'),
    edit: (code) => api(`roles/${code}`),
    delete: (code) => api(`roles/${code}`),
    update: (code) => api(`roles/${code}`),
    statusChange: (code) => api(`roles/status-change/${code}`),

    allList: () => api('roles/list'),
  },
  user: {
    list: () => api('users'),
    create: () => api('users'),
    edit: (code) => api(`users/${code}`),
    delete: (code) => api(`users/${code}`),
    update: (code) => api(`users/${code}`),
    statusChange: (code) => api(`users/status-change/${code}`),
    allList: () => api('users/list'),
  },
  customer: {
    list: () => authapi('user/list'),
    allList: () => authapi('user/cms-list'),
    statusChange: (code) => authapi(`user/status-change/${code}`),
    femaleVerification: (code) => authapi(`user/female-verification/${code}`),
    getCustomerByMobile: (code) => authapi(`user/getUserByMobile/${code}`),
  },
  userGroup: {
    list: () => api('user-groups'),
    create: () => api('user-groups'),
    edit: (code) => api(`user-groups/${code}`),
    update: (code) => api(`user-groups/${code}`),
    delete: (code) => api(`user-groups/${code}`),
    statusChange: (code) => api(`user-groups/status-change/${code}`),
    filter: () => api('user-groups/group/filter'),
    handsetBrands: () => api('user-groups/getBranding-name'),
    handsetDevices: () => api(`user-groups/getDevice-name`),
    // allList: () => api('user-groups/list'),
  },
  widget: {
    list: () => api('widgets'),
    edit: (code) => api(`widgets/${code}`),
    update: (code) => api(`widgets/${code}`),

    statusChange: (code) => api(`widgets/status-change/${code}`),
  },
  template: {
    list: () => api('templates'),
    create: () => api('templates'),
    edit: (code) => api(`templates/${code}`),
    update: (code) => api(`templates/${code}`),
    delete: (code) => api(`templates/${code}`),
    statusChange: (code) => api(`templates/status-change/${code}`),
    statusCheck: (code) => api(`templates/status-check/${code}`),
  },

  stations: {
    list: () => api('stations'),
    create: () => api('stations'),
    edit: (code) => api(`stations/${code}`),
    update: (code) => api(`stations/${code}`),
    statusChange: (code) => api(`stations/status-change/${code}`),
    stationWiseLmc: (code) => api(`stations/stationWise-lmc/${code}`),
    allList: () => api('stations/list'),
    detailsByCode: (code) => api(`stations/api/${code}`),
    authorityList: () => api('authority/list'),
  },
  lines: {
    list: () => api('lines'),
    create: () => api('lines'),
    edit: (code) => api(`lines/${code}`),
    update: (code) => api(`lines/${code}`),
    statusChange: (code) => api(`lines/status-change/${code}`),
    serviceStatusChange: (code) => api(`lines/service-status-change/${code}`),
    allList: () => api('lines/list'),
  },

  profileMenu: {
    profileList: () => api('profile/getProfile-list'),
    updateProfileMenu: () => api('profile/update-profileMenu'),
    statusChange: (profileId) => api(`profile/status-change/${profileId}`),
    changePlatform: (profileId, updateKey, add) =>
      api(`profile/change-platform/${profileId}/${updateKey}/${add}`),
  },
  gates: {
    list: () => api('gates'),
    create: () => api('gates'),
    edit: (code) => api(`gates/${code}`),
    update: (code) => api(`gates/${code}`),
    statusChange: (code) => api(`gates/status-change/${code}`),
    allList: () => api('gates/list'),
    //   listByStaion: () => api('gates/list'),
  },
  lifts: {
    list: () => api('lifts'),
    create: () => api('lifts'),
    edit: (code) => api(`lifts/${code}`),
    update: (code) => api(`lifts/${code}`),
    statusChange: (code) => api(`lifts/status-change/${code}`),
    allList: () => api('lifts/list'),
    //   listByStaion: () => api('lifts/list'),
  },
  interchangeTimes: {
    list: () => api('interchange-times'),
    create: () => api('interchange-times'),
    edit: (code) => api(`interchange-times/${code}`),
    update: (code) => api(`interchange-times/${code}`),
    statusChange: (code) => api(`interchange-times/status-change/${code}`),
  },
  nearByPlaces: {
    list: () => api('near-by-places'),
    create: () => api('near-by-places'),
    edit: (code) => api(`near-by-places/${code}`),
    update: (code) => api(`near-by-places/${code}`),
    statusChange: (code) => api(`near-by-places/status-change/${code}`),

    allList: () => api('near-by-places/list'),
  },
  parkings: {
    list: () => api('parkings'),
    create: () => api('parkings'),
    edit: (code) => api(`parkings/${code}`),
    update: (code) => api(`parkings/${code}`),
    statusChange: (code) => api(`parkings/status-change/${code}`),
  },
  platforms: {
    list: () => api('platforms'),
    create: () => api('platforms'),
    edit: (code) => api(`platforms/${code}`),
    update: (code) => api(`platforms/${code}`),
    allList: () => api('platforms/list'),
    statusChange: (code) => api(`platforms/status-change/${code}`),
  },
  routes: {
    list: () => api('routes'),
    create: () => api('routes'),
    edit: (code) => api(`routes/${code}`),
    update: (code) => api(`routes/${code}`),
    statusChange: (code) => api(`routes/status-change/${code}`),
  },
  staticPages: {
    list: () => api('static-page'),
    headers: () => api(`static-page/get-headers`),
    create: () => api('static-page'),
    edit: (code) => api(`static-page/${code}`),
    update: (code) => api(`static-page/${code}`),
    allList: () => api('static-page/list'),
    statusChange: (code) => api(`static-page/status-change/${code}`),
    uploader: () => api('static-page/upload'),
    getTitles: () => api('static-page/get-titles'),
  },
  stationDetails: {
    list: () => api('station-details'),
    create: () => api('station-details'),
    edit: (code) => api(`station-details/${code}`),
    update: (code) => api(`station-details/${code}`),
    allList: () => api('station-details/list'),
    statusChange: (code) => api(`station-details/status-change/${code}`),
  },
  facilities: {
    list: () => api('station-facilities'),
    create: () => api('station-facilities'),
    edit: (code) => api(`station-facilities/${code}`),
    update: (code) => api(`station-facilities/${code}`),
    allList: () => api('station-facilities/list'),
    statusChange: (code) => api(`station-facilities/status-change/${code}`),
  },
  nonRevenueTime: {
    list: () => api('non_revenue_time'),
    create: () => api('non_revenue_time'),
    edit: (code) => api(`non_revenue_time/${code}`),
    update: (code) => api(`non_revenue_time/${code}`),
    statusChange: (code) => api(`non_revenue_time/status-change/${code}`),
  },
  stationStatus: {
    list: () => api('station_status'),
    create: () => api('station_status'),
    edit: (code) => api(`station_status/${code}`),
    update: (code) => api(`station_status/${code}`),
    statusChange: (code) => api(`station_status/status-change/${code}`),
  },
  stationFirstLineTime: {
    list: () => api('station-first-and-line-time'),
    create: () => api('station-first-and-line-time'),
    edit: (code) => api(`station-first-and-line-time/${code}`),
    update: (code) => api(`station-first-and-line-time/${code}`),
    statusChange: (code) => api(`station-first-and-line-time/status-change/${code}`),
  },
  tourGuide: {
    list: () => api('tour-guide'),
    create: () => api('tour-guide'),
    edit: (code) => api(`tour-guide/${code}`),
    update: (code) => api(`tour-guide/${code}`),
    statusChange: (code) => api(`tour-guide/status-change/${code}`),
  },
  lineStatus: {
    list: () => api('line_status'),
    create: () => api('line_status'),
    edit: (code) => api(`line_status/${code}`),
    update: (code) => api(`line_status/${code}`),
    statusChange: (code) => api(`line_status/status-change/${code}`),
  },

  brands: {
    list: () => ecommerceapi('brands'),
    create: () => ecommerceapi('brands'),
    edit: (code) => ecommerceapi(`brands/${code}`),
    update: (code) => ecommerceapi(`brands/${code}`),
    statusChange: (code) => ecommerceapi(`brands/status-change/${code}`),
    allList: () => ecommerceapi('brands/list'),
  },
  category: {
    list: () => ecommerceapi('categories'),
    create: () => ecommerceapi('categories'),
    edit: (code) => ecommerceapi(`categories/${code}`),
    update: (code) => ecommerceapi(`categories/${code}`),
    statusChange: (code) => ecommerceapi(`categories/status-change/${code}`),
    allList: () => ecommerceapi('categories/list'),
    childCategory: (code) => ecommerceapi(`categories/sub-categories/${code}`),
    multipleChildCategory: () => ecommerceapi(`categories/multi-sub-categories`),
    brandList: () => ecommerceapi(`categories/brandList`),
  },

  product: {
    list: () => ecommerceapi('products'),
    temporary_list: () => ecommerceapi('temp-products'),
    create: () => ecommerceapi('products'),
    edit: (code) => ecommerceapi(`products/${code}`),
    editTemp: (code) => ecommerceapi(`temp-products/${code}`),

    update: (code) => ecommerceapi(`products/${code}`),
    updateTemp: (code) => ecommerceapi(`temp-products/${code}`),
    rejectionReasons: () => ecommerceapi(`temp-products/rejection-reasons`),
    rejectionReasons2: () => ecommerceapi(`temp-products/rejection-reasons`),
    sentForApproval: (code) => ecommerceapi(`temp-products/sendToApproval/${code}`),

    statusChange: (code) => ecommerceapi(`products/status-change/${code}`),
    markForRetail: (code) => ecommerceapi(`products/mark-for-retail/${code}`),
    allList: () => ecommerceapi('products/list'),
    bulk: () => ecommerceapi('products/bulk'),
    inventory: () => ecommerceapi('products/inventory-bulk'),

    image: () => ecommerceapi('products/images'),
    delImage: () => ecommerceapi('products/del-images'),
    algolia: () => ecommerceapi('products/algolia/sync'),
    productStatusChange: (code) => ecommerceapi(`temp-products/status-change/${code}`),
    export: () => ecommerceapi('products/export'),
    checkDuplicateSKU: () => ecommerceapi('products/check-sku'),
    urlFilter: () => ecommerceapi('products/get-filters'),
    productByCategory: (code) => ecommerceapi(`products/products-by-category/${code}`),
    productBySku: (code) => ecommerceapi(`products/app/${code}`),
  },

  templateEcommerce: {
    list: () => ecommerceapi('templates'),
    create: () => ecommerceapi('templates'),
    edit: (code) => ecommerceapi(`templates/${code}`),
    update: (code) => ecommerceapi(`templates/${code}`),
    delete: (code) => ecommerceapi(`templates/${code}`),
    statusChange: (code) => ecommerceapi(`templates/status-change/${code}`),
  },

  orders: {
    list: () => orderapi('orders'),
    EcomOrderList: () => orderapi('orders/orderList'),
    // list: () => `http://localhost:3004/order/v1/orders`,
    // create: () => orderapi('orders'),
    edit: (code) => orderapi(`orders/${code}`),
    // edit: (code) => `http://localhost:3004/order/v1/orders/${code}`,
    update: (code) => orderapi(`orders/${code}`),
    delete: (code) => orderapi(`orders/${code}`),
    statusChange: (code) => orderapi(`orders/status-change/${code}`),
    generateInvoice: () => orderapi(`orders/generate-invoice`),
    downloadInvoice: (code) => orderapi(`orders/invoice-download/${code}`),
    downloadShipping: (code) => externalapi(`locker/shipping-download/${code}`),
    pickup: (code) => externalapi(`locker/dispatch`),
    orderListReport: (start, end) =>
      orderapi(
        `orders/orderListReport/${moment(start).format('DD-MM-YYYY')}/${moment(end).format(
          'DD-MM-YYYY',
        )}`,
      ),
    orderReport: () => orderapi(`orders/ordersReport/vendor`),
  },

  advertisement: {
    list: () => api('page-ads'),
    // create: () => orderapi('orders'),
    edit: (code) => api(`page-ads/${code}`),
    update: (code) => api(`page-ads/${code}`),
    // delete: (code) => orderapi(`orders/${code}`),
    // statusChange: (code) => orderapi(`orders/status-change/${code}`),
  },

  vendor: {
    list: () => ecommerceapi('vendors'),
    create: () => ecommerceapi('vendors'),
    edit: (code) => ecommerceapi(`vendors/${code}`),
    update: (code) => ecommerceapi(`vendors/${code}`),
    statusChange: (code) => ecommerceapi(`vendors/status-change/${code}`),
    allList: () => ecommerceapi('vendors/list'),
    pincodeDetails: (code) => ecommerceapi(`vendors/pinCode_detail/${code}`),
    panVerify: () => ecommerceapi(`vendors/verifyPan`),
    gstVerify: () => ecommerceapi(`vendors/verifyGstNumber`),
    bankDetailsVerify: () => ecommerceapi(`vendors/bankverify`),
    deliveryAgency: () => ecommerceapi(`vendors/getDeliveryAgency`),
  },

  navbar: {
    list: () => api('navbar/navbar-list'),
    updateNavbar: () => api('/navbar/update-navbar'),
    statusChange: (code) => api(`/navbar/navbar-statusChange/${code}`),
  },

  dropbox: {
    list: () => api('drop-box'),
    create: () => api('drop-box'),
    edit: (code) => api(`drop-box/${code}`),
    update: (code) => api(`drop-box/${code}`),
    allList: () => api('drop-box/list'),
    statusChange: (code) => api(`drop-box/status-change/${code}`),
    //locker
    lockerStatus: () => externalapi(`locker/locker-state`),
    openLocker: () => externalapi(`locker/locker-open-request`),
    lockerHistory: () => externalapi(`locker/locker-history`),
  },

  booking: {
    list: () => externalapi('locker/list'),
  },
  ticket: {
    list: () => externalapi('ticket/cms/list'),
  },

  virtualStore: {
    list: () => ecommerceapi('virtual-stores'),
    create: () => ecommerceapi('virtual-stores'),
    edit: (code) => ecommerceapi(`virtual-stores/${code}`),
    update: (code) => ecommerceapi(`virtual-stores/${code}`),
    delete: (code) => ecommerceapi(`virtual-stores/${code}`),

    allList: () => ecommerceapi('virtual-stores/list'),
    statusChange: (code) => ecommerceapi(`virtual-stores/status-change/${code}`),
    copy: () => ecommerceapi('virtual-stores/copy'),
    publish: (code) => ecommerceapi(`virtual-stores/publish/${code}`),
  },
  store: {
    list: () => ecommerceapi('stores'),
    create: () => ecommerceapi('stores'),
    edit: (code) => ecommerceapi(`stores/${code}`),
    update: (code) => ecommerceapi(`stores/${code}`),
    delete: (code) => ecommerceapi(`stores/${code}`),

    allList: () => ecommerceapi('stores/list'),
    statusChange: (code) => ecommerceapi(`stores/status-change/${code}`),
    copy: () => ecommerceapi('stores/copy'),
    publish: (code) => ecommerceapi(`stores/publish/${code}`),
  },
  virtualStations: {
    list: () => ecommerceapi('virtual-station'),
    create: () => ecommerceapi('virtual-station'),
    edit: (code) => ecommerceapi(`virtual-station/${code}`),
    update: (code) => ecommerceapi(`virtual-station/${code}`),
    statusChange: (code) => ecommerceapi(`virtual-station/status-change/${code}`),
    bulkUpdate: () => ecommerceapi(`virtual-station/bulk-update`),
  },
  cardRecharge: {
    list: () => externalapi('card-recharges/list'),
    //history for card txn
    history: () => api('smart-cards/last-transaction'),
    download: (startDate, endDate) =>
      externalapi(
        `card-recharges/download?startDate=${moment(startDate).format(
          'DD-MM-YYYY',
        )}&endDate=${moment(endDate).format('DD-MM-YYYY')}`,
      ),
    mandatedCardDetails: () => api('smart-cards/mandated-card-details'),
    revokeAutoTopup: () => externalapi('card-recharges/revoke-auto-topup'),

    //card recharge revoke

    lastDetails: (orderId) => externalapi(`card-recharges/recharge-by-orderId/${orderId}`),
    revokeRecharge: () => externalapi('card-recharges/cardRecharge-refund'),
  },

  notification: {
    list: () => api('push-notifications'),
    create: () => api('push-notifications'),
  },

  coupons: {
    list: () => checkoutapi('coupons'),
    create: () => checkoutapi('coupons'),
    edit: (code) => checkoutapi(`coupons/${code}`),
    update: (code) => checkoutapi(`coupons/${code}`),
    statusChange: (code) => checkoutapi(`coupons/status-change/${code}`),
    allList: () => checkoutapi('coupons/list'),
  },
  discounts: {
    list: () => checkoutapi('discounts'),
    create: () => checkoutapi('discounts'),
    edit: (code) => checkoutapi(`discounts/${code}`),
    update: (code) => checkoutapi(`discounts/${code}`),
    statusChange: (code) => checkoutapi(`discounts/status-change/${code}`),
    allList: () => checkoutapi('discounts/list'),
    delete: (code) => checkoutapi(`discounts/${code}`),
  },

  fares: {
    list: () => api('fares'),
    edit: (code) => api(`fares/${code}`),
    update: (code) => api(`fares/${code}`),
    // statusChange: (code) => checkoutapi(`fears/status-change/${code}`),
    // allList: () => checkoutapi('fears/list'),
  },

  //dashboard
  dashboard: {
    categories: (code) => reportapi(`dashboard/getCategories/${code}`),
    pie: () => reportapi(`dashboard/dashboard-pie`),
    successTable: () => reportapi(`dashboard/success-table-data`),
    successTransactions: () => reportapi(`dashboard/successful-transactions-list`),
    carbonData: () => reportapi(`dashboard/dashboard-carbon`),
    failedTransactions: () => reportapi(`dashboard/failed-transactions-list`),
    lockerSummary: () => reportapi(`dashboard/locker-summary`),

    // Dashboard-Beta
    autopeCategories: (code) => reportapi(`dashboard/autope/getCategories/${code}`),
    autopePie: () => reportapi(`dashboard/autope-dashboard-pie`),
    table: () => reportapi(`dashboard/autope-table-data`),
    transaction: () => reportapi(`dashboard/captured-transactions-list`),
    dmrcReport: () => reportapi(`dashboard/getDmrcReport`),
  },

  //lost-nd-found
  lostfound: {
    getList: () => api(`lost-and-found/getList`),
    getItem: (code) => api(`lost-and-found/getItem/${code}`),
    add: () => api(`lost-and-found/add-found-item`),
    delete: (code) => api(`lost-and-found/delete/${code}`),
    status: (code) => api(`lost-and-found/status-change/${code}`),
    update: (code) => api(`lost-and-found/update/${code}`),
    upload: () => api('lost-and-found/fileUpload'),
  },

  //manage service ads
  adv: {
    getList: () => api('adv/'),
    getByType: (code) => api(`adv/cms/${code}`),
    update: (code) => api(`adv/${code}`),
  },

  signup: {
    fieldList: () => api('signup-screens/fields'),
    add: () => api('signup-screens'),
    details: () => authapi('auth/signup-screens'),
  },

  //courierapi
  courier: {
    allZoneList: () => courierapi(`courier-metro`),
    ZoneList: (code) => courierapi(`courier-metro/?courierPartnerName=${code}`),
    cityList: () => courierapi('courier-metro/getCities'),
    zoneCities: () => courierapi('courier-metro/getZoneCities'),
    wef: (code, slug) => courierapi(`courier-metro/get-wefs/${code}/${slug}`),
    rateCards: () => courierapi('courier-metro/getRateCards'),
    specialRate: () => courierapi('courier-metro/special-rate/stationCode'),
    validateSlabs: () => courierapi('courier-metro/validate-slabs'),
    fareCalculator: () => courierapi('courier-metro/calculate-tariff'),
    special: () => courierapi('courier-metro/special-rate'),
    specialStatus: (code) => courierapi(`courier-metro/special-rate/statusChange/${code}`),
    zoneStatus: (code) => courierapi(`courier-metro/status-change/${code}`),
    editZone: (code) => courierapi(`courier-metro/zone/${code}`),
    addSpecialRate: () => courierapi('courier-metro/special-rate/add'),
    citybyPincode: (code) => courierapi(`courier-metro/getCityStatebyPincode/${code}`),
    getPartner: () => courierapi('courier/getCourierPartner'),
    deletePartner: (code) => courierapi(`courier/deleteCourierPartner/${code}`),
    addPartner: () => courierapi(`courier/createCourierPartner`),
    getParcel: () => courierapi(`courier/getparcelContent`),
    deleteParcel: (code) => courierapi(`courier/deleteparcelContent/${code}`),
    getPickup: () => courierapi(`courier-booking/get-pickup-list`),
    getPicked: () => courierapi(`courier-booking/get-picked-list`),
    courierStatusUpdate: (code) => courierapi(`courier-booking/update-status/${code}`),
  },
  //partnerTariffApi
  partner: {
    ZoneList: (code) => courierapi(`courier-metro/?courierPartnerName=${code}`),
    zoneCities: () => courierapi('courier-metro/getZoneCities'),
    rateCards: () => courierapi('partner/getRateCards'),
    special: () => courierapi('partner/special-rate'),
    validateSlabs: () => courierapi('partner/validate-slabs'),
    wef: (code, slug) => courierapi(`partner/get-wefs/${code}/${slug}`),
    addSpecialRate: () => courierapi('partner/special-rate/add'),
    specialStatus: (code) => courierapi(`partner/special-rate/statusChange/${code}`),
    fareCalculator: () => courierapi('partner/calculate-tariff'),
    specialRate: () => courierapi('partner/special-rate/stationCode'),
    citybyPincode: (code) => courierapi(`courier-metro/getCityStatebyPincode/${code}`),
    getPartner: () => courierapi('courier/getCourierPartner'),
  },
  //pickupList for pickr
  pickr: {
    create: () => api('pickr/'),
    getById: (code) => api(`pickr/${code}`),
    update: (code) => api(`pickr/${code}`),
    statusChange: (code) => api(`pickr/status-change/${code}`),

    //pickrs
    getPickrList: () => api('users/pickrList/'),
    getPickrList2: () => ecommerceapi('retail/pickrList/'),
    getDroprList: () => ecommerceapi('retail/droprList/'),
    getPickrStation: () => api('users/pickrStations'),
    getPickrById: (code) => api(`users/${code}`),
    getStationbyLines: () => api('lines/stationsByLines'),
    assignStations: () => courierapi('pickr/assignStations'),
    reAssignOrders: () => courierapi('pickr/reAssignOrders'),
    getPickrByStation: (code) => courierapi(`pickr/pickrByStation/?stationCode=${code}`),

    stationBookingCheck: () => courierapi(`pickr/stationBookingCheck`),

    stationCheckBeforeAssigning: () => courierapi(`pickr/stationCheckBeforeAssigning`),
  },

  //file-status-history
  fileStatus: {
    fileHistory: () => api('metroCardTxn/fileProcessHistory'),
  },
  //locker-support
  lockerSupport: {
    openLocker: () => externalapi('locker/open-via-cms'),
    lockerStatusChange: () => externalapi('locker/locker-status-change'),
  },
  //callback-requests
  callbackRequest: {
    list: () => api('contact-us/callback-request'),
    myList: (code) => api(`contact-us/myList/${code}`),
    supportList: () => api('contact-us/supportlist'),
    getById: (code) => api(`contact-us/callback-request/${code}`),
    update: (code) => api(`contact-us/callback-request/${code}`),
  },

  //reports
  reports: {
    list: () => api('reports/getReports'),
    purposes: () => api('reports/purposes'),
    download: () => api('reports/downloadMultipleFiles'),
    consolidatedReport: () => reportapi('reports/consolidated-time-elapsed'),

    stationWise: (station) => reportapi(`reports/stationwise-time-elapsed/${station}`),

    cardDoesNotExists: (start, end) =>
      reportapi(
        `reports/cardNotExists/${moment(start).format('DD-MM-YYYY')}/${moment(end).format(
          'DD-MM-YYYY',
        )}`,
      ),
    fareServiceSummaryReport: (start, end) =>
      reportapi(
        `reports/fare-service-summary/${moment(start).format('DD-MM-YYYY')}/${moment(end).format(
          'DD-MM-YYYY',
        )}`,
      ),

    retailReport: (startDate, endDate) =>
      reportapi(
        `reports/retailInventoryReport/${moment(startDate).format('DD-MM-YYYY')}/${moment(
          endDate,
        ).format('DD-MM-YYYY')}`,
      ),

    qrReport: (startDate, endDate) =>
      reportapi(
        `reports/qr-sent-to-dmrc/${moment(startDate).format('DD-MM-YYYY')}/${moment(endDate).format(
          'DD-MM-YYYY',
        )}`,
      ),

    getPickedListReport: () => reportapi(`reports/get-picked-list-report?`),

    courierReport: (start, end) =>
      reportapi(
        `reports/courier-report/${moment(start).format('DD-MM-YYYY')}/${moment(end).format(
          'DD-MM-YYYY',
        )}`,
      ),
    orderListReport: (start, end) =>
      reportapi(
        `reports/orderListReport/${moment(start).format('DD-MM-YYYY')}/${moment(end).format(
          'DD-MM-YYYY',
        )}`,
      ),

    appExceptionReport: (start, end) =>
      reportapi(
        `reports/appError-report/${moment(start).format('DD-MM-YYYY')}/${moment(end).format(
          'DD-MM-YYYY',
        )}`,
      ),

    refundReport: (startDate, endDate, selectedStatus) =>
      reportapi(
        `reports/refund-summary/${moment(startDate).format('DD-MM-YYYY')}/${moment(endDate).format(
          'DD-MM-YYYY',
        )}/${selectedStatus}`,
      ),
    lockerExtendReport: (startDate, endDate) =>
      reportapi(
        `reports/lockerExtend/${moment(startDate).format('DD-MM-YYYY')}/${moment(endDate).format(
          'DD-MM-YYYY',
        )}`,
      ),

    discountCouponReport: (startDate, endDate) =>
      reportapi(
        `reports/discount-coupon/${moment(startDate).format('DD-MM-YYYY')}/${moment(endDate).format(
          'DD-MM-YYYY',
        )}`,
      ),

    malfunctionedLockersReport: () => reportapi(`reports/malfunctioned-lockers`),
    productList: () => reportapi(`customer/getProducts`),
    lockerStations: () => reportapi(`customer/stations`),
    customerDetailList: () => reportapi(`customer/customerDetail`),
    customerDetailList2: () => reportapi(`customer/customerDetailCount`),

    pgLogReport: (startDate, endDate) =>
      reportapi(
        `reports/pgLog-report/${moment(startDate).format('DD-MM-YYYY')}/${moment(endDate).format(
          'DD-MM-YYYY',
        )}`,
      ),

    svqrCycleReport: (startDate, endDate) =>
      reportapi(
        `reports/svQr-cycle-query/${moment(startDate).format('DD-MM-YYYY')}/${moment(
          endDate,
        ).format('DD-MM-YYYY')}`,
      ),

    rideServicesReport: (startDate, endDate, service) =>
      reportapi(
        `reports/last-mile-funnel/${moment(startDate).format('DD-MM-YYYY')}/${moment(
          endDate,
        ).format('DD-MM-YYYY')}/${service}`,
      ),

    walletReport: () => reportapi(`reports/wallet-report`),
    fastTagMandateList: () => reportapi('reports/fast-Job'),
    billersList: () => reportapi('reports/utility-billerCategory'),
    utilityReport: () => reportapi('reports/utility-report'),

    pspFunnelReport: (psp, start, end) =>
      reportapi(
        `reports/pspFunnelReport/${psp}/${moment(start).format('YYYY-MM-DD')}/${moment(end).format(
          'YYYY-MM-DD',
        )}`,
      ),

    irctcApiCall: (start, end) =>
      reportapi(
        `reports/irctc-api-call/${moment(start).format('DD-MM-YYYY')}/${moment(end).format(
          'DD-MM-YYYY',
        )}`,
      ),

    ticketCancelledReport: (start, end, reportType) =>
      reportapi(
        `reports/cancelled-tickets-report/${moment(start).format('DD-MM-YYYY')}/${moment(
          end,
        ).format('DD-MM-YYYY')}/${reportType}`,
      ),

    mjqrTransactionReport: (start, end) =>
      reportapi(
        `reports/mjqrGenerationReport/${moment(start).format('DD-MM-YYYY')}/${moment(end).format(
          'DD-MM-YYYY',
        )}`,
      ),

    mjqrTxnCountReport: (start, end) =>
      reportapi(
        `reports/mjqrGeneration&Count/${moment(start).format('DD-MM-YYYY')}/${moment(end).format(
          'DD-MM-YYYY',
        )}`,
      ),

    salesList: () => reportapi(`event/event-ticketing-itpo`),
    htCitySaleList: () => reportapi('event/event-ticketing-htCity'),
    itpoCheckedIn: () => reportapi(`event/itpo-checked-in`),
    eventWiseSale: () => reportapi(`event/event-datewise-sale`),
    golfCartTickets: () => reportapi(`event/itpo-golf-cart`),
    rapidoTransactionsReport: (start, end) =>
      reportapi(
        `reports/get-rapido-transactions/${moment(start).format('DD-MM-YYYY')}/${moment(end).format(
          'DD-MM-YYYY',
        )}`,
      ),

    lmcTransactionsReport: (start, end, provider) =>
      reportapi(
        `reports/lmc-transactions-report/${moment(start).format('DD-MM-YYYY')}/${moment(end).format(
          'DD-MM-YYYY',
        )}/${provider}`,
      ),
    tpiUserList: () => reportapi('reports/tpi-user-list'),
    rrtsReport: () => reportapi('reports/rrts-user-report'),

    // Compiled Report
    compiledReport: () => reportapi('reports/compiled-report'),

    mjqrSearchReport: () => reportapi('reports/sv-qr-history'),

    allLockerState: () => reportapi('reports/all-locker-state-report'),

    // Event
    eventList: () => reportapi(`event/get-events`),
    eventDetails: () => reportapi(`event/get-events-details`),
    scannedDetails: () => reportapi(`event/get-wbf-event-details`),
    surajKundSoldDetails: () => reportapi(`event/get-surajKund-TxnDetails`),
    eventUserDetails: () => reportapi('event/get-gbexpo-tickets'),

    // Rydr Settlement Report
    rydrSettlementReport: (start, end) =>
      reportapi(
        `reports/rydr-sheRyds-settlement-report/${moment(start).format('DD-MM-YYYY')}/${moment(
          end,
        ).format('DD-MM-YYYY')}`,
      ),
    dailySettlementReport: (start, end) =>
      reportapi(
        `reports/daily-settlement-report/${moment(start).format('DD-MM-YYYY')}/${moment(end).format(
          'DD-MM-YYYY',
        )}`,
      ),
    csvUploader: () => reportapi('reports/upload-csv'),
    mjqrUnfulfillUploader: () => reportapi('reports/mjqr-unfulfilled-txn-upload'),
    refundTxnReport: (startDate, endDate, service) =>
      reportapi(
        `reports/refund-transactions/${moment(startDate).format('DD-MM-YYYY')}/${moment(
          endDate,
        ).format('DD-MM-YYYY')}/${service}`,
      ),
    shuttleReport: (startDate, endDate) =>
      reportapi(
        `reports/shuttle-booking-report/${moment(startDate).format('DD-MM-YYYY')}/${moment(
          endDate,
        ).format('DD-MM-YYYY')}`,
      ),
  },

  splash: {
    list: () => api('splash/getSplashes'),
    create: () => api('splash'),
    splashById: (code) => api(`/${code}`),
    update: (code) => api(`/${code}`),
    delete: (code) => api(`splash/deleteSplashScreen/${code}`),
  },
  email: {
    emailUpload: () => api('sendEmail/emailUpload'),
    sendEmail: () => api('sendEmail'),
  },
  onboarding: {
    list: () => api('onboarding/get-onboarding-list'),
    create: () => api('onboarding/'),
    screenById: (code) => api(`onboarding/get-onboarding/${code}`),
    update: (code) => api(`onboarding/update-by-id/${code}`),
    delete: (code) => api(`onboarding/delete-by-id/${code}`),
    statusChange: (code) => api(`onboarding/statusChange/${code}`),
  },
  agentSearch: {
    find: () => reportapi('reports/pgandRefundData'),
  },
  utr: {
    list: () => api(`utr/get-utr-list`),
    create: () => api(`utr/post-utr`),
  },

  retail: {
    retailList: () => ecommerceapi('retail/get-retail-inventory-list'),
    duplicacyCheck: () => ecommerceapi('retail/check-inventory-duplicacy'),
    lockerAvailability: () => ecommerceapi('retail/check-locker-availability'),
    createInventory: () => ecommerceapi('retail/create-retail-inventory'),
    editInventory: (slug) => ecommerceapi(`retail//get-inventory-by-batch/${slug}`),
    deleteInventory: (slug) => ecommerceapi(`retail/delete-retail-inventory/${slug}`),
    batchAssign: () => ecommerceapi(`retail/batch-assign`),
    retailLockers: () => ecommerceapi('retail/cms-retail-lockers'),
    readyForOrders: () => ecommerceapi(`retail/ready-for-order-cms`),
    retrieveLockers: () => ecommerceapi(`dropr/retrieve-lockers-app`),
    revokeLockers: () => ecommerceapi(`dropr/revoke-lockers-app`),
    droprList: () => ecommerceapi(`retail/droprList`),
    assignLockerToRetrieve: () => ecommerceapi(`retail/retrieve-lockers-cms`),
    markToRevoke: () => ecommerceapi(`retail/revoke-lockers`),
    getDroprRetrieveLockers: () => ecommerceapi(`retail/getDroprRetrieveLockers`),

    vendorList: () => ecommerceapi('retail/get-vendor'),
    productList: () => ecommerceapi('retail/get-no-variant-vendors'),
  },
  wallet: {
    lastDetail: (mobileNumber) => externalapi(`wallet/getWalletDetails/${mobileNumber}`),
    recharge: () => externalapi(`wallet/rechargeWallet`),
  },

  // Discount Coupon
  discountCoupon: {
    list: () => externalapi(`discount-coupon/list`),
    generateCoupons: () => externalapi(`discount-coupon/generate-coupons`),
    couponData: (id) => externalapi(`discount-coupon/coupon-data/${id}`),
    editCoupon: (id) => externalapi(`discount-coupon/edit-coupon/${id}`),
    updateStatus: (id) => externalapi(`discount-coupon/update-status/${id}`),
  },

  lmc: {
    bookingList: () => cabapi(`rydr/cabList`),
    markupList: () => cabapi(`rydr/markupList`),
    editMarkupList: (code) => cabapi(`rydr/markupById/${code}`),
    updateMarkupList: (code) => cabapi(`rydr/updateMarkup/${code}`),
    GeolocationList: () => cabapi(`rydr/geolocationServiceList`),
    editGeoLocationList: (code) => cabapi(`rydr/geolocationServiceById/${code}`),
    updateGeoLocationList: (code) => cabapi(`rydr/updateGeolocationService/${code}`),
    GeolocationCityWise: () => cabapi(`rydr/geolocationServiceCityList`),
    AddCity: () => cabapi(`rydr/createGeolocationServiceCity`),
    DeleteGeoLocationService: (code) => cabapi(`rydr/deleteGeolocationServiceCity/${code}`),
  },

  eventFacility: {
    createFacility: () => externalapiV2(`event-facility/create-facility`),
    facilityList: () => externalapiV2(`event-facility/list`),
    allFacilites: () => externalapiV2(`event-facility/list-all`),
    facilityDetails: (facilityId) => externalapiV2(`event-facility/facility-details/${facilityId}`),
    editFacility: (facilityId) => externalapiV2(`event-facility/edit-facility/${facilityId}`),
    statusChange: (facilityId) => externalapiV2(`event-facility/status-change/${facilityId}`),
  },
  event: {
    createEvent: () => externalapiV2(`event/create-event`),
    eventList: () => externalapiV2(`event/list`),
    eventDetails: (eventId) => externalapiV2(`event/event-details/${eventId}`),
    editEvent: (eventId) => externalapiV2(`event/edit-event/${eventId}`),
  },
  eventTicket: {
    ticketList: () => externalapiV2(`event-ticketing/list`),
  },

  stockGenerator: {
    eventList: () => externalapiV2(`event-ticketing/get-events`),
    eventDetails: () => externalapiV2(`event-ticketing/get-events-details`),
    updateStock: () => externalapiV2(`event-ticketing/update-event-stock`),
    generateStock: () => externalapiV2(`event-ticketing/generate-stock`),
  },

  //Dynamic-Images
  dynamicImage: {
    addScreen: () => api(`images/add-screenContent`),
    screenList: () => api(`images/screen-list`),
    screenDetails: (screenContentId) => api(`images/getScreenContent/${screenContentId}`),
    editScreen: (screenContentId) => api(`images/edit-screenContent/${screenContentId}`),
    statusChange: (code) => api(`images/screen-statusChange/${code}`),
  },

  configuration: {
    configurationList: () => reportapi(`config/config-list`),
    addConfiguration: () => reportapi(`config/add-config`),
    configurationDetails: (configurationId) => reportapi(`config/getConfig/${configurationId}`),
    editConfiguration: (configurationId) => reportapi(`config/edit-config/${configurationId}`),
  },

  serviceConfig: {
    serviceList: () => reportapi('config/service-list'),
    getServices: () => reportapi('config/get-services'),
    editService: (serviceId) => reportapi(`config/getService/${serviceId}`),
    updateService: (serviceId) => reportapi(`config/update-service-pgOptions/${serviceId}`),
  },

  eventBooking: {
    liveEvent: () => externalapiV2(`event/live-event`),
    bookTicket: () => externalapiV2(`event-ticketing/book-ticket`),
    confirmTicket: () => externalapiV2(`event-ticketing/confirm-ticket`),
    agentList: () => externalapiV2(`event-agent/eventAgent-list`),
    assignFacility: (agentID) => externalapiV2(`event-agent/assign-facility/${agentID}`),
  },

  cityMaster: {
    citiesList: () => cabapi('serviceableCities/city-list'),
    citiesListCMS: () => cabapi('serviceableCities/city-list-cms'),
    subCities: () => cabapi('serviceableCities/getSubCitiesList'),
    create: () => cabapi('serviceableCities/createServiceableCity'),
    delete: (code) => cabapi(`serviceableCities/serviceableCity/${code}`),
    update: (code) => cabapi(`serviceableCities/serviceableCity/${code}`),
    status: (code) => cabapi(`serviceableCities/status-change/${code}`),
    allCityList: () => cabapi('serviceableCities/all-city-list'),
  },
  tariff: {
    citiesList: () => cabapi('serviceableCities/city-list'),
    citiesListCMS: () => cabapi('serviceableCities/city-list-cms'),
    wef: () => cabapi('rydrTariff/get-wefs'),
    rateCard: () => cabapi('rydrTariff/getRateCards'),
    validate: () => cabapi('rydrTariff/validate-slabs'),
    fareCalculator: () => cabapi('rydrTariff/tariff-cms'),
    vehicleMaster: () => cabapi('vehicleMaster/list-dropdown'),
  },

  //d2cTariff
  D2cTariff: {
    citiesListCMS: () => rydrapi('d2cFareMatrix/city-list-cms'),
    wef: () => rydrapi('d2cFareMatrix/get-wefs'),
    rateCard: () => rydrapi('d2cFareMatrix/getRateCards'),
    validate: () => rydrapi('d2cFareMatrix/validate-slabs'),
    fareCalculator: () => cabapi('rydrTariff/tariff-cms'),
  },

  D2cShift: {
    shiftList: () => rydrapi('shift/shift-list'),
    addShift: () => rydrapi('shift/create-shift'),
    shiftById: (code) => rydrapi(`shift/shift-details/${code}`),
    editShift: (code) => rydrapi(`shift/edit-shift/${code}`),
    statusChange: (code) => rydrapi(`shift/shift-statusChange/${code}`),
  },

  D2cAgent: {
    agentList: () => rydrapi('D2C-Agent/d2c-agent-list'),
    addAgent: () => rydrapi('D2C-Agent/create-d2c-agent'),
    agentById: (code) => rydrapi(`D2C-Agent/d2c-agent-details/${code}`),
    editAgent: (code) => rydrapi(`D2C-Agent/edit-d2c-agent/${code}`),
    statusChange: (code) => rydrapi(`shift/shift-statusChange/${code}`),
  },

  D2cRoster: {
    rosterList: () => rydrapi('roster/roster-list'),
    addRoster: () => rydrapi('roster/create-roster'),
    rosterById: (code) => rydrapi(`roster/roster-details/${code}`),
    editRoster: (code) => rydrapi(`roster/edit-roster/${code}`),
    // getShiftList: (code) => rydrapi(`roster/agent-shift/${code}`),
    getShiftList: (code) => rydrapi(`shift/shift-list-type/${code}`),

    getAgentList: (code) => rydrapi(`roster/d2c-agentList/${code}`),
  },

  Supervisor: {
    supervisorList: () => rydrapi('supervisor'),
    addSupervisor: () => rydrapi('supervisor'),
    supervisorById: (code) => rydrapi(`supervisor/supervisor-details/${code}`),
    editSupervisor: (code) => rydrapi(`supervisor/edit-supervisor/${code}`),
    supervisorStationWise: (code) => rydrapi(`supervisor/supervisor-list-station/${code}`),
  },

  vehicleManagement: {
    vehicle: () => cabapi('vehicleMaster/list'),
    addVehicle: () => cabapi('vehicleMaster/addVehicle'),
  },
  manageServices: {
    getServicesList: () => externalapi(`/service/service-list`),
    updateCouponApplicable: (code) => externalapi(`/service/update-coupon-applicable/${code}`),
  },

  //Shuttle-service

  shuttleTxn: {
    getTxns: () => cabapi(`shuttleBooking/bookings-list`),
  },

  shuttleInv: {
    getInventory: () => cabapi(`shuttleBooking/inventory-details`),
    cancel: () => externalapi(`shuttleBooking/bus-cancelled`),
  },
  themes: {
    list: () => api('themes'),
    create: () => api('themes'),
    update: (code) => api(`themes/${code}`),
    themesList: () => api(`themes/getThemesByMode`),
  },

  //Svqr-Report
  Svqr: {
    list: () => reportapi('reports/sv-Qr-query'),
    resolveQuery: (queryId) => reportapi(`reports/SVQR-query-resolve/${queryId}`),
  },

  EventDashboard: {
    IITFReport: () => reportapi(`event/eventTicketing-itpo`),
  },

  //Post-code
  postCode: {
    postCodeSearch: () => reportapi('reports/post-code-search'),
  },

  paymentsDashboard: {
    paymentDashboardTable: (startDate, endDate, purpose) =>
      reportapi(
        `paymentsDashboard/payments-dashboard/${moment(startDate).format('DD-MM-YYYY')}/${moment(
          endDate,
        ).format('DD-MM-YYYY')}/${purpose}`,
      ),
  },
};

export default endpoints;
