import moment from 'moment';
const api = (endpoint) => `/ry/v1/${endpoint}`;

const endpoint = {
  cityMaster: {
    citiesList: () => api('serviceableCities/city-list'),
    citiesListCMS: () => api('serviceableCities/city-list-cms'),
    subCities: () => api('serviceableCities/getSubCitiesList'),
    create: () => api('serviceableCities/createServiceableCity'),
    delete: (code) => api(`serviceableCities/serviceableCity/${code}`),
    update: (code) => api(`serviceableCities/serviceableCity/${code}`),
    status: (code) => api(`serviceableCities/status-change/${code}`),
    allCityList: () => api('serviceableCities/all-city-list'),
  },
  customer: {
    list: () => api('driver/list/customer'),
    delete: () => api('customer/deleteUser'),
  },
  rydr: {
    list: () => api('driver/list/rydr'),
    details: (code) => api(`driver/getDriverDocs/${code}`),
    bankDetails: (code) => api(`payout/bankDetails-cms/${code}/bankAccount`),
    upiDetails: (code) => api(`payout/bankDetails-cms/${code}/upi`),
    status: () => api('driver/changeDocStatus'),
    delete: () => api('customer/deleteUser'),
    remark: (code) => api(`driver/remark/${code}`),
    rydrActivelist: () => api(`reports/rydr-list`),
    rydrPaymentList: () => api('ride/payment-collected-list'),
    paymentCollectedUpdation: () => api('ride/payment-collectedBy-Cms'),
  },
  tariff: {
    citiesList: () => api('serviceableCities/city-list'),
    citiesListCMS: () => api('serviceableCities/city-list-cms'),
    wef: () => api('rydrTariff/get-wefs'),
    rateCard: () => api('rydrTariff/getRateCards'),
    validate: () => api('rydrTariff/validate-slabs'),
    fareCalculator: () => api('rydrTariff/tariff-cms'),
    vehicleMaster: () => api('vehicleMaster/list-dropdown'),
  },
  lessonPlan: {
    list: () => api('quiz/all-lessons'),
    get: (code) => api(`quiz/get-lesson/${code}`),
    add: () => api('quiz/add-lesson'),
    update: (code) => api(`quiz/update-lesson/${code}`),
    delete: (code) => api(`quiz/delete-lesson/${code}`),
    video: () => api(`splash/upload-training-video`),
    quiz: () => api('quiz/ques'),
  },
  rideParam: {
    list: () => api('rideParameter/getParam'),
    get: (code) => api(`rideParameter/getParam/${code}`),
    create: () => api('rideParameter/addParam'),
    update: (code) => api(`rideParameter/update/${code}`),
  },
  oms: {
    list: (type) => api(`ride/bookingList/${type}`),
  },
  vehicleManagement: {
    vehicle: () => api('vehicleMaster/list'),
    addVehicle: () => api('vehicleMaster/addVehicle'),
  },
  busMaster: {
    busList: () => api('shuttleBus'),
    addBusDetails: () => api('shuttleBus'),
    editBusDetails: (code) => api(`shuttleBus/bus-detailsById/${code}`),
    updateBusDetails: (code) => api(`shuttleBus/updateBus-details/${code}`),
  },
  shuttleStop: {
    getList: () => api(`shuttleStop/list`),
    createStop: () => api(`shuttleStop`),
    getStops: () => api(`shuttleStop`),
    editStop: (code) => api(`shuttleStop/${code}`),
    updateStop: (code) => api(`shuttleStop/${code}`),
    statusChange: (code) => api(`shuttleStop/status-change/${code}`),
  },
  shuttleFare: {
    getFares: () => api(`shuttleFare`),
    editFare: (code) => api(`shuttleFare/${code}`),
    updateFare: (code) => api(`shuttleFare/${code}`),
    addFare: () => api(`shuttleFare`),
  },
  shuttleRoute: {
    getList: () => api(`shuttleRoute/list`),
    createRoute: () => api(`shuttleRoute`),
    getRoutes: () => api(`shuttleRoute`),
    editRoute: (code) => api(`shuttleRoute/${code}`),
    updateRoute: (code) => api(`shuttleRoute/${code}`),
    routeDetail: (code) => api(`shuttleRoute/${code}`),
    statusChange: (code) => api(`shuttleRoute/status-change/${code}`),
  },
  shuttleBus: {
    getList: () => api(`shuttleBus`),
  },
  shuttleDriver: {
    getList: () => api(`driver/list/rydr`),
  },
  shuttleInv: {
    getList: () => api(`shuttleInventory/shuttle-inventory-list`),
    createInventory: () => api(`shuttleInventory/create-shuttle-inventory-manually`),
  },
  reports: {
    rydrSettlementReport: (start, end) =>
      api(
        `reports/rydr-settlement-report/${moment(start).format('DD-MM-YYYY')}/${moment(end).format(
          'DD-MM-YYYY',
        )}`,
      ),

    rydrRegistrationReport: (start, end) =>
      api(
        `reports/rydr-registration-report/${moment(start).format('DD-MM-YYYY')}/${moment(
          end,
        ).format('DD-MM-YYYY')}`,
      ),
    rydrTransactionsReport: (start, end) =>
      api(
        `reports/rydr-transactions-report/${moment(start).format('DD-MM-YYYY')}/${moment(
          end,
        ).format('DD-MM-YYYY')}`,
      ),

    rydrOnlineReport: (date, memberId) =>
      api(`reports/rydr-status-report/${moment(date).format('DD-MM-YYYY')}/${memberId}`),

    rydrPaymentCollectionList: () => api(`/ride/payment-collected-list`),
  },
  notifications: {
    send: () => api('notifications/send'),
  },
};
export default endpoint;
