module.exports = {
  metro: `${process.env.REACT_APP_API_GATEWAY}`, //metro
  catalog: `${process.env.REACT_APP_API_GATEWAY_ECOMMERCE}`, //catalog
  order: `${process.env.REACT_APP_API_GATEWAY_ORDER}`, //order
  auth: `${process.env.REACT_APP_API_GATEWAY_AUTH}`, //auth
  external: `${process.env.REACT_APP_API_GATEWAY_EXTERNAL}`, //external
  checkout: `${process.env.REACT_APP_API_GATEWAY_CHECKOUT}`, //checkout
  courier: `${process.env.REACT_APP_API_GATEWAY_COURIER}`, //courier
  reports: `${process.env.REACT_APP_API_GATEWAY_REPORTS}`, //reports
  cab: `${process.env.REACT_APP_API_GATEWAY_CAB}`, //cab
  rydr: `${process.env.REACT_APP_API_GATEWAY_RYDR}`, //rydr
  sheRyds: `${process.env.REACT_APP_API_GATEWAY_SHERYDS}`, //SheRydss
};
